// icon:chevron-back | Ionicons https://ionicons.com/ | Ionic Framework
import * as React from "react";

function IconForward(props) {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
      {...props}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M 13.5,19.5 21,12 m 0,0 -7.5,-7.5 M 21,12 H 3"></path>
    </svg>
  )
}

export default IconForward;
