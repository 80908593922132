// import React, {useState, useEffect, useRef, useContext} from "react"
import React, {useEffect, useContext} from "react"
// import DOMPurify from 'dompurify'
// import { useLocation } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import SimpleTextPage from "./SimpleTextPage"
import InterviewContext from "./InterviewContext"
import {getCookie, exitQuickly} from "./Utilities"

import './InterviewWrappper.css'

const TranslatedPage = (props) => {
  const lang = getCookie("DA-language")
  // eslint-disable-next-line
  const { t, i18n } = useTranslation()
  const translate = i18n.getFixedT(lang)

  const {state, dispatch} = useContext(InterviewContext)
  useEffect(() => {
    if (state.nextState !== null && typeof(state.nextState) != "undefined") {
      if (state.nextState === "quickExit") {
        exitQuickly()
      }

      // if (state.nextState === "goBack" && state.data.allow_going_back) {
        // props.goPrevQuestion
      //   dispatch({
      //     type: 'UPDATE_NEXT_STATE',
      //     nextState: null
      //   })
      // }

      if (state.nextState === "setLanguageVi") {
        document.cookie = "DA-language=vi; SameSite=Strict; Secure"
        dispatch({
          type: 'UPDATE_NEXT_STATE',
          nextState: null
        })
      }

      if (state.nextState === "setLanguageEn") {
        document.cookie = "DA-language=en; SameSite=Strict; Secure"
        dispatch({
          type: 'UPDATE_NEXT_STATE',
          nextState: null
        })
      }
    }
  }, [state.nextState, state.data]) // eslint-disable-line react-hooks/exhaustive-deps

  document.title = translate(props.heading)

  const debugFlag = false
  const sessIDStoredInCookie = getCookie("DA-sessionID")
  const languageStoredInCookie = getCookie("DA-language")
  let debugInfo = ''
  if (debugFlag) {
    debugInfo = (
      <h1 className = "debug-info">
        Session id = {sessIDStoredInCookie}<br/>
        Language = {languageStoredInCookie}
      </h1>
    )
  }

  return (
    <>
      {debugInfo}

      <div>
        <div id="interview-background"></div>
        <SimpleTextPage heading={translate(props.heading)} mainText={translate(props.mainText)}/>
      </div>
    </>
    )
}

export default TranslatedPage;
