import {React} from "react"
import TranslatedPage from "./TranslatedPage"

const Terms = () => {

  return (
    <TranslatedPage heading='terms-heading' mainText='terms-text'/>
    )
}

export default Terms
