import * as React from "react";

function IconReload(props) {
  return (
    <svg
      fill="#ffffff"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="0.6"
      aria-hidden="true"
      {...props}
    >
      <path d="m3.9879 12.112c0-3.947 3.1642-7.8662 8.0893-7.8662 3.9774 0 5.9446 2.8625 6.7822 4.3856h-2.4869a0.71587 0.69613 0 0 0 0 1.3923h4.2952a0.71587 0.69613 0 0 0 0.71587-0.69613v-4.1768a0.71587 0.69613 0 0 0-1.4317 0v2.5242c-1.0079-1.7292-3.3417-4.8214-7.8746-4.8214-5.8172 0-9.5211 4.6432-9.5211 9.2585 0 4.6153 3.7039 9.2585 9.5211 9.2585 2.7833 0 5.0999-1.072 6.7492-2.7038a9.6929 9.4256 0 0 0 2.0445-3.017 0.71588 0.69614 0 1 0-1.3244-0.52906 8.2612 8.0333 0 0 1-1.741 2.5701c-1.3902 1.3783-3.3445 2.2875-5.7284 2.2875-4.9252 0-8.0893-3.9192-8.0893-7.8662z"
       />
    </svg>
  )
}

export default IconReload;
