import React from 'react'
import Tooltip from './Tooltip'

const preProcessForTerms = (text) => {
  let terms = []
  if (text !== null && typeof(text) !== 'undefined' && text.length > 0) {
    terms = text.match(/{(.*?)}/g)

    if (terms !== null && typeof(terms) !== 'undefined' && terms.length > 0) {
      terms = terms.map((t) => {return t.substring(1,t.length-1).trim()})
      // console.log("terms = ", terms)
    terms.forEach((t, i) => {
      text = text.replace("{" + t +"}",
      "<a id='tooltip-anchor-" + i + "' href='/#' class='termToDefine' " +
      "onClick='return false'>" + t + "</a>")
    });
    }
  }

  return {text: text, terms: terms}
}

const InterviewMainText = (props) => {
  const preProcessedText = preProcessForTerms(props.interviewText)
  const mainText = preProcessedText.text
  const terms = preProcessedText.terms

  return(
    <>
      <h1 className="interview-h1 text-lg lg:text-2xl xl:text-3xl font-bold text-brandCharcoal">
        { <div dangerouslySetInnerHTML={{__html: props.inteviewTitle }} /> }
      </h1>
      <div className="interview-body mt-2 text-brandTextDark lg:mt-4">
        { <div dangerouslySetInnerHTML={{__html: mainText}} /> }
        {/* Need to wait for page to load so we are not rendering old terms */}
        { terms !== null && terms.length > 0 && props.isLoadedInApp === null &&
        <Tooltip terms={terms} definedTerms={props.interviewTerms} />
        }
      </div>
    </>
  )
}

export default InterviewMainText;
