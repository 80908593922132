import React, {useState, useEffect, useLayoutEffect} from "react"

import MenuItem from "./MenuItem"
// import IconBack from "../../icons/IconBack"
import './CardPage.css'
import './MainMenuPage.css'

const MainMenuPage = (props) => {
  const [scriptElement, setScriptElement] = useState(null)

  let menuType = "default"
  if (props.menuType) {
    menuType = props.menuType
  }
  useEffect(() => {
    if (typeof(props.interview.script) !== "undefined") {
      const newElement = document.createElement('script')
      setScriptElement(newElement)
      const inlineCode = document.createTextNode(props.interview.script)
      newElement.appendChild(inlineCode)
      document.body.appendChild(newElement)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.interview.script])

  useLayoutEffect(() => {
    if (scriptElement !== null) {
      document.body.removeChild(scriptElement)
      setScriptElement(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.interview.script])

  return (
    <>
      {/* Interview Header */}
      <h1 className="interview-h1 text-lg lg:text-2xl xl:text-3xl font-bold text-brandCharcoal">
        { props.interview.questionText }
      </h1>

      {/* Interview Text, really the help on "choice" items */}
      {props.interview.fields[0].choices.map(
      (choice, i) =>
        choice.help && choice.help.length > 0  &&
      <div className={`mt-2 text-brandTextDark lg:text-md ${i === 0 ? "lg:mt-4" : "lg:mt-2"}`} key = {i}>
        {choice.help}
      </div>
      ) }

      {/* "Menu Items */}
      <div className="flex flex-wrap -mx-4">

        { props.interview.fields &&
          props.interview.fields.length > 0 &&
          props.interview.fields[0].choices &&
          props.interview.fields[0].choices.length > 0 &&
          props.interview.fields[0].choices.map((interviewObj, index) => (
            <MenuItem key={index}
              index={index}
              name={props.interview.fields[0].variable_name || interviewObj.variable_name}
              label={interviewObj.label}
              text={interviewObj.help}
              value={interviewObj.value}
              buttonColor={interviewObj.color}
              isLoadedInApp={props.isLoadedInApp}
              showButtonSpinner={props.showButtonSpinner}
              handleClick={props.handleClick}
              menuType={menuType}
          />
        ))}

      </div>
    </>
  )
}

export default MainMenuPage;
