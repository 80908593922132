import { Tooltip as ReactTooltip } from "react-tooltip"
import 'react-tooltip/dist/react-tooltip.css'
import './Tooltip.css'

const Tooltip = (props) => {
  return (
    <>
      {props.terms.map((t, i) => {
        return (<ReactTooltip key={i} anchorId={"tooltip-anchor-" + i}
          html={props.definedTerms[t.toLowerCase()]}
          place="bottom" events={['click']} className="LTHTooltip" />)
       })}
    </>
  )
}

export default Tooltip
