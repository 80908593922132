import React, {useEffect, useContext} from "react"
// import { useLocation } from "react-router-dom"
import { useTranslation } from 'react-i18next'
// import HelpButton from "./HelpButton"
import ModalHelp from "./ModalHelp"
// import BackLink from "./BackLink"
import ModalConfirmWindow from "./ModalConfirmWindow"
import ModalShowMessageWindow from "./ModalShowMessageWindow"
import ModalShowGenMessageWindow from "./ModalShowGenMessageWindow"
import InterviewContext from "./InterviewContext"
import { getCookie } from "./Utilities"

const ModalWindows = (props) => {
  const lang = getCookie("DA-language")
  // eslint-disable-next-line
  const { t, i18n } = useTranslation()
  const translate = i18n.getFixedT(lang)
  const { state, dispatch } = useContext(InterviewContext)

  const closeModalWindow = () => {
    dispatch({ type: 'UPDATE_MODAL_WIN_STATE', modalWindowState: null })
  }

  // Modal help window
  const modalSwitch = (state.modalWindowState === "modalHelp") ? true: false

  // Modal Confirm Window
  const confirmSwitch = (state.modalWindowState === "modalConfirm") ? true: false
  const showConfirmWindow = () => {
    dispatch({ type: 'UPDATE_MODAL_WIN_STATE', modalWindowState: "modalConfirm" })
  }

  // Modal Urgent help window
  const urgentHelpSwitch = (state.modalWindowState === "urgentHelp") ? true: false
  const showModalUrgentHelp =  () => {
    dispatch({ type: 'UPDATE_MODAL_WIN_STATE', modalWindowState: "urgentHelp" })
  }

  // Modal Contact Info window
  const genModalSwitch = (state.modalWindowState === "contactInfo") ? true: false
  const showModalContactInfo =  () => {
    dispatch({ type: 'UPDATE_MODAL_WIN_STATE', modalWindowState: "contactInfo" })
  }

  // Check global state
  useEffect(() => {
    if ( state.nextState !== null && typeof(state.nextState) != "undefined") {
      if (state.nextState === "restart") {
        showConfirmWindow()
        dispatch({ type: 'UPDATE_NEXT_STATE', nextState: null })
      }
      if (state.nextState === "showUrgentHelp") {
        showModalUrgentHelp()
        dispatch({ type: 'UPDATE_NEXT_STATE', nextState: null })
      }
      if (state.nextState === "showContactInfo") {
        showModalContactInfo()
        dispatch({ type: 'UPDATE_NEXT_STATE', nextState: null })
      }
    }

  }, [state.nextState]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      { props.interview !== null && props.interview.help && props.interview.help.label.length > 0 &&
      <ModalHelp
        modalSwitch={modalSwitch}
        closeModalHelp={closeModalWindow}
        closeModalHelpButtonText = {translate("exit-help-modal-txt")}
        help={props.interview.help}
        helpText={props.interview.helpText}
      />
      }

      <ModalConfirmWindow
        confirmSwitch={confirmSwitch}
        closeConfirmWindow={closeModalWindow}
        confirmHeader={translate("restart-confirm-header")}
        confirmText={translate("restart-confirm-text")}
        yes={translate("yes-answer")}
        no={translate("no-answer")}
        restartInterview={props.restartInterview}
      />

      <ModalShowMessageWindow
        confirmSwitch={urgentHelpSwitch}
        closeModalUrgentHelp={closeModalWindow}
        urgentHeader={translate("urgent-help")}
        urgentText={translate("urgent-help-text")}
        urgentCloseText={translate("OK-answer")}
      />

      <ModalShowGenMessageWindow
        confirmSwitch={genModalSwitch}
        closeModalGenWindow={closeModalWindow}
        genModalHeader={translate("contact-info-header")}
        genModalText={translate("pclc-contact-text")}
        geModalCloseText={translate("OK-answer")}
      />
    </>
  )
}

export default ModalWindows;
