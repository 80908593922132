import React, {useState, useEffect, useLayoutEffect} from "react"
import { useTranslation } from 'react-i18next'
import { getCookie } from "../../Utilities"
import ApplicationButton from "../ApplicationButton"

import './FillInTheBlanks.css'

const multipleFieldsClick = (e, fields) => {
  let fieldsWithValues = []
  let selectedIndex, selectedValue
  for (let i = 0; i < fields.length; i++) {
    if (fields[i].datatype === "note") {
      selectedValue = fields[i].note
      fieldsWithValues[i]= {name: "note", value: selectedValue}
    } else {
      selectedIndex =  document.getElementsByName(fields[i].variable_name)[0].selectedIndex
      selectedValue = document.getElementsByName(fields[i].variable_name)[0].options[selectedIndex].value
      fieldsWithValues[i]={name: fields[i].variable_name, value: selectedValue}
    }
  }
  return fieldsWithValues
}

const validateFields = (e, fields) => {
  let selectedIndex, selectedValue
  for (let i = 0; i < fields.length; i++) {
    if (typeof(fields[i].variable_name) !== "undefined") {
      selectedIndex =  document.getElementsByName(fields[i].variable_name)[0].selectedIndex
      selectedValue = document.getElementsByName(fields[i].variable_name)[0].options[selectedIndex].value
      if (selectedValue === "") {
        document.getElementsByName(fields[i].variable_name)[0].classList.add('need-to-fill-in')
        return false
      } else {
        document.getElementsByName(fields[i].variable_name)[0].classList.remove('need-to-fill-in')
      }
    }
  }
  return true
}

const validateSingleField = (e) => {
  document.getElementsByName(e.target.name)[0].classList.remove('need-to-fill-in')
}

const getDefault = (field) => {
  for (let i = 0; i < field.choices.length; i++) {
    if (field.choices[i].default) {
      return field.choices[i].value
    }
    return ''
  }
}

const FillInTheBlanks = (props) => {
  const lang = getCookie("DA-language")
  // eslint-disable-next-line
  const { t, i18n } = useTranslation()
  const translate = i18n.getFixedT(lang)
  const [scriptElement, setScriptElement] = useState(null)

  useEffect(() => {
    if (typeof(props.interview.script) !== "undefined") {
      const newElement = document.createElement('script')
      setScriptElement(newElement)
      const inlineCode = document.createTextNode(props.interview.script)
      newElement.appendChild(inlineCode)
      document.body.appendChild(newElement)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.interview.script])

  useLayoutEffect(() => {
    if (scriptElement !== null) {
      document.body.removeChild(scriptElement)
      setScriptElement(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.interview.script])

  return (
    <>
      {/* Interview Header */}
      <h1 className="interview-h1 text-lg lg:text-2xl xl:text-3xl font-bold text-brandCharcoal">
        { props.interview.questionText }
      </h1>

      <div className="pt-2 fill-in-the-blanks">
      {/* Fill in the blanks panel, with drop down fields */}
      { props.interview.fields &&
        props.interview.fields.length > 0 &&
        props.interview.fields[0].choices &&
        props.interview.fields[0].choices.length > 0 &&
        props.interview.fields.map((interviewObj, interviewObjIndex) => {
          if (interviewObj.datatype === "note") {
            return (<div key={interviewObjIndex} className="fitb-note" dangerouslySetInnerHTML={{__html: interviewObj.note}} />)
          } else {
          return (
            <label key={interviewObjIndex}>
            <div className="fitb-label" dangerouslySetInnerHTML={{__html: interviewObj.label}} />
            <select key={interviewObjIndex} name={interviewObj.variable_name} required defaultValue=""
            onChange={(e) => {validateSingleField(e)} } >
              {interviewObj.choices.map((choiceOption, index) => {
                if (choiceOption.value === "divider") {
                  return (<option key={index} className="divider" disabled value={choiceOption.value}>&#9472;&#9472;&#9472;&#9472;&#9472;</option>)
                } else if (choiceOption.value === "select-title") {
                  return (<option key={index} className="select-title" value="" disabled>{choiceOption.label}</option>)
                } else {
                  return (<option key={index} value={choiceOption.value}>{choiceOption.label}</option>)
                }
              })}
            </select>
            </label>
          )}
        })
      }
      </div>

      {/* "Submit" Button */}
      <div className="pt-4">
        <div>
          <ApplicationButton key={0}
            index = {0}
            id = "multiple-fields-submit"
            name = {props.interview.fields[0].variable_name || props.interview.fields[0].choices.variable_name}
            label = {translate("continue-txt")}
            value = {getDefault(props.interview.fields[0])}
            buttonColor = ""
            handleClick={(e) => {
              if (validateFields(e, props.interview.fields)) {
                props.handleClick(e, multipleFieldsClick(e, props.interview.fields))
              }
            }}
            isLoadedInApp = {props.isLoadedInApp}
            showButtonSpinner = {true}
          />
        </div>
      </div>
    </>
  )
}

export default FillInTheBlanks;
