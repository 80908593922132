// From Remix Icon - "Simply Delightful Icon System"
// See https://remixicon.com and https://github.com/Remix-Design/remixicon
// released under the Apache 2.0 License
import * as React from "react";

function IconUrgentHelp(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      strokeWidth="1.5"
      aria-hidden="true"
      {...props}
    >
      <path fill="none" d="M0 0H24V24H0z"/>
      <path d="M9 7.539L15 21.539 18.659 13 23 13 23 11 17.341 11 15 16.461 9 2.461 5.341 11 1 11 1 13 6.659 13z"/>
    </svg>
  )
}

export default IconUrgentHelp
