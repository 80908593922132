import {React} from "react"
import TranslatedPage from "./TranslatedPage"

const About = () => {

  return (
    <TranslatedPage heading='about-heading' mainText='about-text'/>
    )
}

export default About
