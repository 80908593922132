import {createContext, useReducer} from "react"
import InterviewReducer from "./InterviewReducer"

const initialState = {
  nextState: null,
  modalWindowState: null,
  isLoaded: false,
  data: null,
}

const InterviewContext = createContext()
export  const InterviewProvider = ({ children }) => {
  const [state, dispatch] = useReducer(InterviewReducer, initialState);

  return (
    <InterviewContext.Provider value={{ state, dispatch }}>
      {children}
    </InterviewContext.Provider>
  )
}

export default InterviewContext
