// import React, {useState, useEffect, useLayoutEffect} from "react"
import Interview from "../../Interview"
import BackLink from "../../BackLink"

import '../Interview.css'
import './InterviewWithImage.css'

const InterviewWithImage = (props) => {

  const getColorAndImage = (screen_name) => {
    let custom = {}
    switch(screen_name) {
      case "show_services_01":
        custom = {url:  "url(\"https://legaltechhelper.com.au/assets/customers/avwa/img/avwa-services-16x9.svg\")", color: "#FFF8D4"}
        break
      case "legal_services_intro":
        custom = {url:  "url(\"https://legaltechhelper.com.au/assets/customers/avwa/img/legal-services-16x9.svg\")", color: "#f7edff"}
        break
      case "mental_health_intro_01":
        custom = {url:  "url(\"https://legaltechhelper.com.au/assets/customers/avwa/img/mental-health-16x9.svg\")", color: "#EAECFF"}
        break
      case "show_interpreter_01":
        custom = {url:  "url(\"https://legaltechhelper.com.au/assets/customers/avwa/img//interpreting-16x9.svg\")", color: "#ffe8d3"}
        break
      case "ending_screen":
        custom = {url:  "url(\"https://legaltechhelper.com.au/assets/customers/avwa/img/outro-16x9.svg\")", color: "#F6E7F5"}
        break
      case "show_gen_court_dn_00":
        custom = {url:  "url(\"https://legaltechhelper.com.au/assets/customers/avwa/img/notice-of-hearing.png\")", color: "#ffe8d3"}
        break
      default:
        custom = {url:  "url(\"https://legaltechhelper.com.au/assets/customers/avwa/img//splash-16x9.svg\")", color: "#FFF8D4"}
    }
    return custom
  }

  // JS Script being loaded in Interview, therefore no need to execute it here

  // console.log("About to load script...")
  // const [scriptElement, setScriptElement] = useState(null)
  const customColors = getColorAndImage(props.interview.fields[0].variable_name)
  const bgColor = customColors.color
  const bgImageURL = customColors.url
  const extraCSS = props.interview.fields[0].variable_name === "show_gen_court_dn_00"?true:false

  // console.log( "extraScreen = ", props.interview.fields[0].variable_name)
  // console.log( "bgColor = ", bgColor)
  // console.log( "bgImageURL = ", bgImageURL)

  // useEffect(() => {
  //   if (typeof(props.interview.script) !== "undefined") {
  //     const newElement = document.createElement('script')
  //     setScriptElement(newElement)
  //     const inlineCode = document.createTextNode(props.interview.script)
  //     newElement.appendChild(inlineCode)
  //     document.body.appendChild(newElement)
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.interview.script])

  // useLayoutEffect(() => {
  //   if (scriptElement !== null) {
  //     document.body.removeChild(scriptElement)
  //     setScriptElement(null)
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.interview.script])

  return (
    <>
      <div className="flex">
        <div id="image-section-wrapper" className="flex flex-col w-full lg:w-2/5 justify-center lg:items-start overflow-hidden" style={{backgroundColor: bgColor}}>
          <div className={`heroSection w-full md:aspect-[21/9] lg:aspect-[40/21] lg:w-3/5
          ${extraCSS ? 'with-extra-border' : ''}`}
          style={{backgroundImage:bgImageURL}}></div>
        </div>

        <div id="text-section" className="w-full">

          {/* Regular Interview (frp, InterviewWithImage) */}
          <div id="interview-background"></div>
          <div id="interview" className="flex">
            <div className="m-auto">

              {/* Back Button */}
              {props.interview.event_list && props.interview.event_list.length > 0 && props.interview.event_list[0] !== process.env.REACT_APP_FIRST_SCREEN &&
              <div className="back-link hidden md:my-4 sm:mx-0 md:block min-w-screen">
                <BackLink goPrevQuestion = {props.goPrevQuestion} />
              </div>
              }

              <div id="interview-outer-container"
              className="px-4 mx-0 md:p-6 md:mx-auto lg:p-10 md:rounded-md md:bg-white md:shadow-2xl md:w-screen max-w-screen-md">
              <Interview
                isLoadedInApp={props.isLoadedInApp}
                showButtonSpinner={props.showButtonSpinner}
                interview={props.interview}
                sessionID={props.sessionID}
                handleClick={props.handleClick}
                goPrevQuestion = {props.goPrevQuestion}
                restartInterview = {props.restartInterview}
                screenType = "regular"
              />
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default InterviewWithImage;
