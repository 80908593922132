import React from "react"
import { useTranslation } from 'react-i18next'
import { getCookie } from "../../Utilities"

import IconBack from "../../icons/IconBack"
import IconForward from "../../icons/IconForward"

const ResultSetLabel = (props) => {
  const lang = getCookie("DA-language")
  // eslint-disable-next-line
  const { t, i18n } = useTranslation()
  const translate = i18n.getFixedT(lang)
  let label

  if (props.label === "Previous") {
    label = translate("previous-label")
    return (
      <span>
      <IconBack className="inline stroke-width-2-25 text-white h-4 w-4 mb-1 md:mb-0 mr-1 md:mr-2" />
      {label}
      </span>
    )
  }

  if (props.label === "Next") {
    label = translate("next-label")
    if (props.isLoadedInApp === null) {
      return (
        <span>
        {label}
        <IconForward className="inline stroke-width-2-25 text-white h-4 w-4 mb-1 md:mb-0 ml-1 md:ml-2" />
        </span>
      )
    } else {
      return (
        <span>
        {label}
        <IconForward className="inline stroke-width-2-25 transition-to-gray h-4 w-4 mb-1 md:mb-0 ml-1 md:ml-2" />
        </span>
      )
    }
  }

  if (props.label === "Continue") {
    label = translate("continue-txt")
  }

  if (props.label !== "Next" && props.label !== "Previous" && props.label !== "Continue") {
    label = props.label
  }

  if (props.label !== "Next" && props.label !== "Previous") {
    return (
      <span>
      {label}
      </span>
    )
  }
}

export default ResultSetLabel;
