import React from "react"
import PathwayWithSteps from "./PathwayWithSteps"

import MenuItem from "../special-screens/MenuItem"
import '../special-screens/CardPage.css'
import '../special-screens/MainMenuPage.css'
import './SpecialMainMenuPage.css'

const SpecialMainMenuPage = (props) => {

  const stepsInPathway = props.interview.fields[0].choices[8].label
  // console.log("stepsInPathway = ", stepsInPathway)
  const overviewLabel = props.interview.fields[0].choices[5].label
  // console.log("overviewLabel = ", overviewLabel)

  return (
    <>
      {/* Interview Header */}
      <h1 className="interview-h1 text-lg lg:text-2xl xl:text-3xl font-bold text-brandCharcoal">
        { props.interview.questionText }
      </h1>

      <div className="interview-body my-4 lg:my-6" key="header-h2-wrapper">
        <h2 className="main-menu-h2">
          {props.interview.fields[0].choices[0].help}
        </h2>
      </div>

      <PathwayWithSteps
        stepsInPathway={stepsInPathway}
      />


      <div className="flex flex-wrap -mx-4" key="overview-wrapper">

      {/* "Overview */}
        { props.interview.fields &&
          props.interview.fields.length > 0 &&
          props.interview.fields[0].choices &&
          props.interview.fields[0].choices.length > 0 &&
          props.interview.fields[0].choices.map((interviewObj, index_overview) => (
            interviewObj.color === "primary" && interviewObj.label === overviewLabel &&
            <MenuItem
              key={"overview-" + index_overview}
              index={index_overview}
              name={props.interview.fields[0].variable_name || interviewObj.variable_name}
              label={interviewObj.label}
              text={interviewObj.help}
              value={interviewObj.value}
              buttonColor={interviewObj.color}
              isLoadedInApp={props.isLoadedInApp}
              showButtonSpinner={props.showButtonSpinner}
              handleClick={props.handleClick}
              menuType="menu-by-two"
            />
        ))}

      {/* "Main Menu Items */}
        { props.interview.fields &&
          props.interview.fields.length > 0 &&
          props.interview.fields[0].choices &&
          props.interview.fields[0].choices.length > 0 &&
          props.interview.fields[0].choices.map((interviewObj, index1) => (
            interviewObj.color === "info" && index1 === parseInt(stepsInPathway) &&

            <MenuItem
              key={"app-button-active-" + index1}
              index={index1}
              name={props.interview.fields[0].variable_name || interviewObj.variable_name}
              label={interviewObj.label}
              text={interviewObj.help}
              value={interviewObj.value}
              buttonColor={interviewObj.color}
              isLoadedInApp={props.isLoadedInApp}
              showButtonSpinner={props.showButtonSpinner}
              handleClick={props.handleClick}
              menuType="menu-by-two"
            />

        ))}

      </div>

      <h2 className="main-menu-h2 pt-4 lg:pt-6 lg:pb-1">
        {props.interview.fields[0].choices[5].help}
      </h2>

      {/* "Additional resources */}
      <div className="flex flex-wrap -mx-4 mb-2">
        { props.interview.fields &&
          props.interview.fields.length > 0 &&
          props.interview.fields[0].choices &&
          props.interview.fields[0].choices.length > 0 &&
          props.interview.fields[0].choices.map((interviewObj, index3) => (
            interviewObj.color === "primary" && interviewObj.label !== overviewLabel &&
            <MenuItem key={"additional-resources-" + index3}
              index={"additional-resources-" + index3}
              name={props.interview.fields[0].variable_name || interviewObj.variable_name}
              label={interviewObj.label}
              text={interviewObj.help}
              value={interviewObj.value}
              buttonColor={interviewObj.color}
              isLoadedInApp={props.isLoadedInApp}
              showButtonSpinner={props.showButtonSpinner}
              handleClick={props.handleClick}
              menuType="menu-by-two"
            />
        ))}
      </div>

      {/* End Pathway button */}
      <div className="flex flex-wrap -mx-4 mb-2">
        { props.interview.fields &&
          props.interview.fields.length > 0 &&
          props.interview.fields[0].choices &&
          props.interview.fields[0].choices.length > 0 &&
          props.interview.fields[0].choices.map((interviewObj, index3) => (
            interviewObj.color === "secondary" && stepsInPathway === "5" &&
            <MenuItem key={"additional-resources-" + index3}
              index={"additional-resources-" + index3}
              name={props.interview.fields[0].variable_name || interviewObj.variable_name}
              label={interviewObj.label}
              text={interviewObj.help}
              value={interviewObj.value}
              buttonColor={interviewObj.color}
              isLoadedInApp={props.isLoadedInApp}
              showButtonSpinner={props.showButtonSpinner}
              handleClick={props.handleClick}
              menuType="menu-by-two"
            />
        ))}
      </div>
    </>
  )
}

export default SpecialMainMenuPage;
