import React from 'react'
import './BlankCanvas.css'

const BlankCanvas = () => {
	return (
    <div id="blank-canvas" className="z-30 h-screen w-screen bg-white">
    </div>
	);
};

export default BlankCanvas;
