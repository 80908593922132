import React, {useState, useEffect, useLayoutEffect, useContext} from "react"
import InterviewContext from "../../InterviewContext"
import InterviewMainText from "../../InterviewMainText"
import HelpButton from "../../HelpButton"
import ApplicationButton from "../ApplicationButton"

import '../Interview.css'

const InterviewWithUrgentHelp = (props) => {
  const { dispatch } = useContext(InterviewContext)
  const [scriptElement, setScriptElement] = useState(null)

  useEffect(() => {
    if (typeof(props.interview.script) !== "undefined") {
      const newElement = document.createElement('script')
      setScriptElement(newElement)
      const inlineCode = document.createTextNode(props.interview.script)
      newElement.appendChild(inlineCode)
      document.body.appendChild(newElement)
    }
    initializeWithUrgentHelp()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.interview.script])

  useLayoutEffect(() => {
    if (scriptElement !== null) {
      document.body.removeChild(scriptElement)
      setScriptElement(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.interview.script])

  // Listen for urgent help link being clicked
  const initializeWithUrgentHelp = () => {
    const urgentHelpLink = document.getElementById('urgentHelpLink')
    urgentHelpLink.addEventListener('click', (e) => {
      e.preventDefault()
      dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'showUrgentHelp' })
    })
  }

  return (
    <>
      {/* Main Text of the Interview*/}
      <InterviewMainText
        isLoadedInApp={props.isLoadedInApp}
        inteviewTitle={props.interview.questionText}
        interviewText={props.interview.subquestionText}
        interviewTerms={props.interview.terms} />

      {/* Application buttons */}
      <div className="pt-4">
        { props.interview.fields &&
          props.interview.fields.length > 0 &&
          props.interview.fields[0].choices &&
          props.interview.fields[0].choices.length > 0 &&
          props.interview.fields[0].choices.map((interviewObj, index) => (
          <ApplicationButton key={index}
            index = {index}
            id = {"app-button-" + index}
            name = {props.interview.fields[0].variable_name || interviewObj.variable_name}
            label =  {interviewObj.label}
            value = {interviewObj.value}
            buttonColor = {interviewObj.color}
            handleClick={props.handleClick}
            isLoadedInApp = {props.isLoadedInApp}
            showButtonSpinner = {props.showButtonSpinner}
          />
        ))}

        {/* Help Button */}
        { props.interview.help && props.interview.help.label && props.interview.help.label.length > 0 &&
          < HelpButton
            isLoadedInApp = {props.isLoadedInApp}
            buttonKey={props.interview.fields[0].choices.length}
            help={props.interview.help}
            helpText={props.interview.helpText}
          />
        }
      </div>
    </>
  )
}

export default InterviewWithUrgentHelp
