const InterviewReducer = (state, action) => {
  switch (action.type) {
    case "GET_INTERVIEW":
      return {
        ...state,
        data: action.payload,
      }
    case "UPDATE_NEXT_STATE":
      return {
        ...state,
        nextState: action.nextState,
      }
    case "UPDATE_INTERVIEW_DATA":
      return {
        ...state,
        data: action.data,
      }
    case "UPDATE_MODAL_WIN_STATE":
      return {
        ...state,
        modalWindowState: action.modalWindowState,
      }
    default:
      return state;
  }
}

export default InterviewReducer
