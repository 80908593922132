import React, {useState, useEffect, useLayoutEffect} from "react"
import { useTranslation } from 'react-i18next'
import { getCookie } from "../../Utilities"
import ApplicationButton from "../ApplicationButton"

import InterviewMainText from "../../InterviewMainText"
import ModalGenericDialog from "../../ModalGenericDialog"
// import BackLink from "../../BackLink"
import ResultSetLabel from "./ResultSetLabel"
import IconBack from "../../icons/IconBack"

import '../Interview.css'
import './ResultSetPage.css'

const ResultSetPage = (props) => {
  const lang = getCookie("DA-language")
  // eslint-disable-next-line
  const { t, i18n } = useTranslation()
  const translate = i18n.getFixedT(lang)

  const [scriptElement, setScriptElement] = useState(null)
  const [currentRecord, setCurrentRecord] = useState(null)
  const [modalSwitch, setModalSwitch] = useState(false)

  const initializeResultSet = () => {
    const items = document.querySelectorAll('.resultset-item-head')
    items.forEach(item => {
      // eslint-disable-next-line
      const ObjectAsString = eval("JSON.stringify({" + (item.querySelector('.lth-object').innerText).toString() + "})")
      const ObjectAsJSON = JSON.parse(ObjectAsString)
      const resultButton = item.querySelector('.button-result-detail')
      if (typeof(resultButton.listen) === "undefined") {
        resultButton.listen = true
        resultButton.addEventListener('click', (e) => {
        setCurrentRecord(ObjectAsJSON)
        showModalHelp(e)
      })
    }})
  }

  const stripExtraCharsFromPhone = (phone_no) => {
    return phone_no.replace(/\D/g,"")
  }

  const shortenMore = (shortWebLink) => {
    const indexforTruncation = shortWebLink.indexOf("/.../")
    if (indexforTruncation > 0) {
      return shortWebLink.substr(0, indexforTruncation + 4)
    } else {
      return shortWebLink
    }
  }

  const putBreakInEmail = (email) => {
    if (email.length > 30) {
      const formattedEmail = email.replace("@", "@</span><br/><span class='ml-6'>");
      return formattedEmail
    } else return email
  }

  const prepareTextForResult = (resultText) => {
    let phone_label = ""
    let phone_label2 = ""
    let preparedText = '<table class="resultset-contact-details">'
    if (resultText !== null) {

      // Address
      if (resultText.address !== "N/A" && resultText.address.trim() !== "No physical location") {
        preparedText += '<tr><td style="height: 100%; vertical-align: top;"><div class="result-vis-on-sm">' + translate("address") + ':&nbsp;&nbsp;</div></td>' +
          '<td>' + resultText.address +
          '</td></tr>'
      }

      // Phone
      if (resultText.phone_label !== "N/A") {
        phone_label = resultText.phone_label + ": "
      } else {
        phone_label = translate("phone") + ": "
      }
      if (resultText.phone !== "N/A") {
        preparedText += '<tr><td><div class="result-vis-on-sm">' + phone_label + '</div></td>' +
          '<td style="vertical-align: bottom;">' +
          '<a class="phone-link" href="tel:' + stripExtraCharsFromPhone(resultText.phone) + '">' + resultText.phone +
          '</a></td></tr>'
      }

      // Second Phone Number
      if (resultText.phone_label2 !== "N/A") {
        phone_label2 = resultText.phone_label2 + ": "
      } else {
        phone_label2 = "&nbsp;"
      }
      if (resultText.phone2 !== "N/A") {
        preparedText += '<tr><td><div class="result-vis-on-sm">' + phone_label2 + ' </div></td>' +
          '<td style="vertical-align: bottom;">' +
          '<a class="phone-link" href="tel:' + stripExtraCharsFromPhone(resultText.phone2) + '">' + resultText.phone2 +
          '</a></td></tr>'
      }

      // Email
      if (resultText.email !== "N/A") {
        preparedText += '<tr><td style="height: 100%; vertical-align: top;"><div class="result-vis-on-sm">' + translate("email") + ': </div></td>' +
          '<td>'+
          '<a key={1} class="email-link result-vis-on-xs-only" href="mailto:' + resultText.email + '"><span>' + putBreakInEmail(resultText.email) +
          '</span></a>' +
          '<a key ={2} class="email-link result-vis-on-sm" href="mailto:' + resultText.email + '"><span>' + resultText.email +
          '</span></a>' +
          '</td></tr>'
      }

      // Second Email
      if (resultText.email2 !== "N/A") {
        preparedText += '<tr><td style="height: 100%; vertical-align: top;"><div class="result-vis-on-sm">&nbsp;</div></td>' +
          '<td>'+
          '<a key={1} class="email-link result-vis-on-xs-only" href="mailto:' + resultText.email2 + '"><span>' + putBreakInEmail(resultText.email2) +
          '</span></a>' +
          '<a key ={2} class="email-link result-vis-on-sm" href="mailto:' + resultText.email2 + '"><span>' + resultText.email2 +
          '</span></a>' +
          '</td></tr>'
      }

      // Website
      if (resultText.website !== "N/A") {
        preparedText += '<tr><td style="height: 100%; vertical-align: top;">' +
          '<div class="hidden sm:inline">' + translate("website") + ':&nbsp;&nbsp;</div>' +
          '</td>' +
          '<td>' +
          '<a class="web-link external-link sm:hidden" target="_blank" href="' + resultText.website + '">' +
          translate("website-link") + '</a>' +

          '<a class="web-link hidden sm:inline lg:hidden" target="_blank" href="' + resultText.website + '">' +
          shortenMore(resultText.website_short) + '</a>' +

          '<a class="web-link hidden lg:inline" target="_blank" href="' + resultText.website + '">' +
          resultText.website_short + '</a>' +

          '</td></tr>'
      }

      preparedText += '</table>'

      // hr on md
      // preparedText += '<div class="hidden md:block mt-3"><hr/></div>'

      // Walk-ins
      if (resultText.walk_in !== "N/A") {
        preparedText += '<div class="mh-service-type-badge mt-3 text-lg text-brandTextDark">' +
          '<span class="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-lime-100 bg-brandPinkVivid rounded-full">' +
          translate("walkins-badge-label").toUpperCase() + '</span>' +
          '<span class="mh-resultset-badge-text m-0" style="padding: 0!important;"> ' + translate("walkins-available") + '</span>' +
          '</div>'
      }

      // Telehealth
      if (resultText.telehealth !== "N/A") {
        preparedText += '<div class="mh-service-type-badge mt-3 text-lg text-brandTextDark">' +
          '<span class="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-lime-100 bg-brandPinkVivid rounded-full">' +
          translate("telehealth-badge-label").toUpperCase() + '</span>' +
          '<span class="mh-resultset-badge-text m-0" style="padding: 0!important;"> ' + translate("telehealth") + '</span>' +
          '</div>'
      }

      // hr on md
      // preparedText += '<div class="hidden md:block my-3"><hr/></div>'

      // Service type mobile text
      preparedText +=
        `<div class="md:hidden mt-3 accordion">
          <div class="accordion-item cursor-pointer" style="cursor: pointer">
            <div class="accordion-item-head" onClick="this.parentNode.querySelector('.item-content-toggle').classList.toggle('hidden'); this.parentNode.querySelector('.line').classList.toggle('hidden');">
              <div>
                ${resultText.service_type}
              </div>

              <div class="accordion-plus bg-brandPrimary hover:bg-brandPrimaryHover text-white text-md px-2 py-2 rounded-md shadow-md lg:font-medium primary" style="color: #ffffff; max-height: 40px;">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                   <path class="line" d="M12 5l0 14"></path>
                   <path d="M5 12l14 0"></path>
                </svg>
              </div>
            </div>
            <div class="pt-3 px-2 hidden item-content-toggle">

              <div class="service-type-description">
                ${getLongText(resultText.service_type)}
                </a>
              </div>
            </div>
          </div>
        </div>`

      // Service type tablet and desktop
      if (resultText.service_type !== "N/A") {
        // preparedText += '<div class="hidden md:block mt-3 lg:mt-4"><hr/></div>'
        // preparedText += '<div class="hidden md:block text-brandTextDark pt-2 lg:pt-4">' + translate("service-type") + ':</div>'
        // preparedText += '<div class="hidden md:block pb-0 lg:pb-2 text-md lg:text-lg text-brandBlueDark font-semibold">' + resultText.service_type + '</div>'
        // preparedText += '<div class="hidden md:block mt-2 mb-3 lg:mt-2 lg:mb-4"><hr/></div>'
        // preparedText += '<div class="hidden md:block text-brandTextDark">' + getLongText(resultText.service_type) + '</div>'
        preparedText +=
          `<div class="hidden md:block text-brandTextDark mt-4">${translate("service-type")}:
          <div class="inline text-md font-medium">${resultText.service_type}</div>
          </div>
          <div class="hidden md:block service-type-description mt-3">
          ${getLongText(resultText.service_type)}
          </div>`
      }
    }

    return preparedText
  }

  const getLongText = (serviceType) => {
    let longtext
    switch(serviceType) {
      case "Mental Health and Wellbeing Local service":
        longtext = translate("mh-and-wellbeing-local")
        break;
      case "Primary Health Network":
        longtext = translate("phn")
        break
      case "Mental Health and Wellbeing Hub":
        longtext = translate("mh-and-wellbeing-hub")
        break
      case "headspace":
        longtext = translate("headspace")
        break
      case "Mental Health and Wellbeing Connect Centre":
        longtext = translate("connect-centre")
        break
      case "Community Mental Health Clinic":
        longtext = translate("community-mh-clinic")
        break
      default:
    }
    return longtext
  }

  useEffect(() => {
    if (typeof(props.interview.script) !== "undefined") {
      const newElement = document.createElement('script')
      setScriptElement(newElement)
      const inlineCode = document.createTextNode(props.interview.script)
      newElement.appendChild(inlineCode)
      document.body.appendChild(newElement)
    }
    initializeResultSet()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.interview.script])

  useLayoutEffect(() => {
    if (scriptElement !== null) {
      document.body.removeChild(scriptElement)
      setScriptElement(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.interview.script])

  initializeResultSet()

  const closeModalWindow = () => {
    setModalSwitch(false)
  }

  const showModalHelp = (e) => {
    e.preventDefault()
    setModalSwitch(true)
  }

  return (
    <>
    <div id="interview-background"></div>
      <ModalGenericDialog
        modalSwitch={modalSwitch}
        closeModalHelp={closeModalWindow}
        closeModalHelpButtonText = {translate("exit-generic-modal-txt")}
        header={currentRecord?currentRecord.name:currentRecord}
        mainText={prepareTextForResult(currentRecord)}
      />

      <div id="interview-background"></div>
      <div id="interview" className="flex">
        <div className="m-auto">

          {/* 'Back to selection screen' button */}
          {props.interview.event_list && props.interview.event_list.length > 0 && props.interview.event_list[0] !== process.env.REACT_APP_FIRST_SCREEN &&
          <div className="back-link hidden md:my-4 sm:mx-0 md:block min-w-screen">
            <a id="back-button" className="text-brandBlue text-lg" href="#/" onClick={(e) => props.goPrevQuestion(e, "mental_health_search")}>
              <IconBack className="inline stroke-width-2-25 text-brandBlue h-5 w-5 mr-2" />
              {translate("back-selection-txt")}
            </a>
          </div>
          }

          {/* Width dependant on screen type*/}
          <div id="interview-outer-container"
          className="px-4 mx-0 md:p-6 md:mx-auto lg:p-10 md:rounded-md md:bg-white md:shadow-2xl md:w-screen max-w-screen-md">


          {/* Main Text of the Interview*/}
          <InterviewMainText
            isLoadedInApp={props.isLoadedInApp}
            inteviewTitle={props.interview.questionText}
            interviewText={props.interview.subquestionText}
            interviewTerms={props.interview.terms} />

          {/* Application Buttons */}
          <div>
             { props.interview.fields &&
               props.interview.fields.length > 0 &&
               props.interview.fields[0].choices &&
               props.interview.fields[0].choices.length > 0 &&
               props.interview.fields[0].choices.map((interviewObj, index) => (
               <ApplicationButton key={index}
                 index = {index}
                 id = {"app-button-" + index}
                 name = {props.interview.fields[0].variable_name || interviewObj.variable_name}
                 label =  {<ResultSetLabel label = {interviewObj.label} isLoadedInApp = {props.isLoadedInApp}/>}
                 value = {interviewObj.value}
                 buttonColor = {interviewObj.color}
                 handleClick={props.handleClick}
                 isLoadedInApp = {props.isLoadedInApp}
                 showButtonSpinner = {props.showButtonSpinner}
               />
             ))}
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default ResultSetPage;
