import {React} from "react"
import TranslatedPage from "./TranslatedPage"

const Privacy = () => {

  return (
    <TranslatedPage heading='privacy-heading' mainText='privacy-text'/>
    )
}

export default Privacy
