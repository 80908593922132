import React from 'react';
import './Spinner.css';

const Spinner = () => {
	return (
    <div className="spinner-container">
	    <div className="spinner-wrapper">
	      <div className="spinner">
	        <div></div>
	        <div></div>
	      </div>
	    </div>
    </div>
	);
};

export default Spinner;
