import React from "react"
import { classNames } from "../../Utilities"

import '../ApplicationButton.css'

const MenuItem = (props) => {
  const button_id = "app-button-" + props.index

  // button on first presentation, before sunmission
  if (props.isLoadedInApp === null) {
    return (
      <>
      <div key={props.index} className={`w-full px-4 ${props.menuType === "menu-by-two" ? "lg:w-1/2" : ""}` +
      `${props.menuType === "menu-by-two-long" ? "xl:w-1/2" : ""}` +
      `${props.menuType === "long" ? "xl:w-full" : ""}` +
      `${props.menuType !== "menu-by-two" && props.menuType !== "menu-by-two-long" && props.menuType !== "long" ? "md:w-1/2 xl:w-1/3" : ""}` }>
        <div className="c-card block rounded-lg overflow-hidden h-full">
          <button key = {props.index}
            id={"app-button-" + props.index}
            className={classNames(
              'w-full mt-5 bg-brandPrimary hover:bg-brandPrimaryHover text-white text-md px-4 py-5 mr-4 rounded-ButtonRadius shadow-md hover:shadow-xl lg:font-medium lg:py-5 lg:px-5 lg:text-md gradient-button',
              (props.buttonColor === "secondary")? "secondary secondary-radio": props.buttonColor
            )}
            style={{ padding: '3rem !important' }}
            onClick={(e) => props.handleClick(e)}
            name={props.name}
            value={props.value}>
              {props.label}
          </button>
        </div>
      </div>
      </>
    )
  } else {
  // when submitting form

    // button clicked
    if ( props.showButtonSpinner && props.isLoadedInApp === button_id) {
      return (
        <>
        <div key={props.index} className={`w-full px-4 ${props.menuType === "menu-by-two" ? "lg:w-1/2" : ""}` +
        `${props.menuType === "menu-by-two-long" ? "xl:w-1/2" : ""}` +
        `${props.menuType === "long" ? "lg:w-full xl:w-full" : ""}` +
        `${props.menuType !== "menu-by-two" && props.menuType !== "menu-by-two-long" && props.menuType !== "long" ? "md:w-1/2 xl:w-1/3" : ""}` }>

          <div className="c-card block rounded-lg overflow-hidden h-full">
            <button key = {props.index}
              id={"app-button-" + props.index}
              className="w-full mt-5 bg-gray-500 text-white text-md px-4 py-5 mr-4 rounded-ButtonRadius shadow-md hover:shadow-xl lg:font-medium lg:py-5 lg:px-8 lg:text-md"
              style={{ padding: '3rem !important' }}
              onClick={(e) => props.handleClick(e)}
              name={props.name}
              value={props.value}>
              <div className="inline-block bg-gradient-to-r from-gray-100 via-gray-400 bg-gray-500 bg-clip-text text-transparent">
               {props.label}
               <div className = "dot-pulse-wrapper-div inline">
                 <div className="dot-pulse inline-block"></div>
               </div>
              </div>
            </button>
          </div>
        </div>
        </>
      )
    } else {
      // button not clicked
      return (
        <>
        <div key={props.index} className={`w-full px-4 ${props.menuType === "menu-by-two" ? "lg:w-1/2" : ""}` +
        `${props.menuType === "menu-by-two-long" ? "xl:w-1/2" : ""}` +
        `${props.menuType === "long" ? "xl:w-full" : ""}` +
        `${props.menuType !== "menu-by-two" && props.menuType !== "menu-by-two-long" && props.menuType !== "long" ? "md:w-1/2 xl:w-1/3" : ""}` }>
          <div className="c-card block rounded-lg overflow-hidden h-full">
            <button key = {props.index}
              id={"app-button-" + props.index}
              className="w-full mt-5 bg-gray-500 text-white text-md px-4 py-5 mr-4 rounded-ButtonRadius shadow-md hover:shadow-xl lg:font-medium lg:py-5 lg:px-8 lg:text-md"
              style={{ padding: '3rem !important' }}
              onClick={(e) => props.handleClick(e)}
              name={props.name}
              value={props.value}>
                {props.label}
            </button>
          </div>
        </div>
        </>
      )
    }
  }
}

export default MenuItem;
