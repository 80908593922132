import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import IconContact from './icons/IconContact'
import { XMarkIcon } from '@heroicons/react/20/solid'

import "./ModalWindow.css"

const ModalShowGenMessageWindow = (props) => {
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={props.confirmSwitch} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {props.closeModalGenWindow()}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:mx-4 md:mx-16 lg:max-w-[600px] ">
              <div className="bg-white px-4 pt-5 pb-4 sm:pb-2">
                <div className="closeModal">
                  <XMarkIcon className="text-slate-900" onClick={(e) => props.closeModalGenWindow()} />
                </div>

                <div>
                  <div className="mt-8 md:mt-0 sm:ml-4 mb-3 md:mb-2">
                    <div className="flex space-between my-4">
                      <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-brandSecondary ml-0 mr-3 sm:h-10 sm:w-10"
                      style={{margin:"auto 1rem auto 0"}}>
                        <IconContact className="inline text-white" width='1.5rem' height='1.5rem' />
                      </div>
                      <Dialog.Title as="h4" className="pt-2 pb-2 text-2xl leading-6 text-brandSecondary">
                        {props.genModalHeader}
                      </Dialog.Title>
                    </div>
                    <div className="mt-2">
                      <div className="text-md text-brandTextDark"
                         dangerouslySetInnerHTML={{__html: props.genModalText}}
                       />
                    </div>
                  </div>
                </div>
              </div>
              <div className="pb-8 md:pb-6 px-4 sm:flex sm:flex-row-reverse sm:px-6 modal-button">
                <button
                  type="button"
                  className="focus:outline-none w-full rounded-ButtonRadius bg-brandSecondary extra-thin-button hover:bg-brandSecondaryHover text-white text-md px-4 py-4 my-2 sm:mb-0 sm:mt-4 shadow-md sm:w-auto lg:font-medium lg:py-4 lg:px-8 lg:text-md"
                  onClick={(e) => props.closeModalGenWindow()}
                >
                  {props.geModalCloseText}
                </button>
              </div>
            </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ModalShowGenMessageWindow
