import * as React from "react";

function IconExit(props) {
  return (
      <svg
        viewBox="0 0 512 512"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        aria-hidden="true"
        strokeWidth="40"
        {...props}
      >
      <path d="M320 176v-40a40 40 0 00-40-40H88a40 40 0 00-40 40v240a40 40 0 0040 40h192a40 40 0 0040-40v-40M384 176l80 80-80 80M191 256h273" />
    </svg>
  )
}

export default IconExit;
