import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import translationsEn from "./component/translations/translationsEn"
import translationsVi from "./component/translations/translationsVi"

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: translationsEn },
      vi: { translation: translationsVi },
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // react already safe from xss
    }
  });

  export default i18n;
